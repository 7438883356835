<script lang="ts">
	import { icons as i, cards as c } from '.';
	import { title } from '$const/website';
	import { ready } from '../pricing/PrincingCards.svelte';
	import type { Coordinates } from '$internal/components/transition/TransitionMorph.svelte';
	import ButtonTextShuffle from '$internal/components/button/ButtonTextShuffle.svelte';
	import HeroCarousel from '$internal/components/hero/HeroCarousel.svelte';
	import TransitionMorph from '$internal/components/transition/TransitionMorph.svelte';

	const icons = [
		i.HeroIconSvelteKit,
		i.HeroIconStytch,
		i.HeroIconStripe,
		i.HeroIconTailwindCSS,
		i.HeroIconMongoDB,
		i.HeroIconSEO
	];

	const cards = [
		c.HeroCardSvelteKit,
		c.HeroCardStytch,
		c.HeroCardStripe,
		c.HeroCardTailwindCSS,
		c.HeroCardMongoDB,
		c.HeroCardSEO
	];

	const cardsMorph: Coordinates[] = [
		[4, 80, 11, 55],
		[5, 75, 10, 90],
		[0, 90, 40, 60],
		[8, 78, 7, 50],
		[3, 80, 20, 80],
		[7, 85, 18, 70]
	];

	const iconsCoordinates: Coordinates[] = [
		[63, 83, 8, 20],
		[35, 45, 8, 20],
		[40, 50, 39, 30],
		[64, 78, 10, 15],
		[35, 50, 16, 26],
		[70, 84, 20, 28]
	];

	const classes: { external: string; internal: string }[] = [
		{
			external: 'border-[#ff3e00]/90',
			internal: 'lg:bg-base-200'
		},
		{
			external: 'border-slate-300/90',
			internal: 'lg:bg-base-300'
		},
		{
			external: 'border-blue-300/90',
			internal: 'lg:bg-base-100'
		},
		{
			external: 'border-[#38bdf8]',
			internal: 'lg:bg-base-300'
		},
		{
			external: 'border-white',
			internal: 'lg:bg-base-100'
		},
		{
			external: 'border-primary',
			internal: 'lg:bg-base-200'
		}
	];
</script>

<HeroCarousel
	{icons}
	{iconsCoordinates}
	class={$$props.class ?? ''}
>
	<header slot="welcome" class="h-full grid place-content-center gap-4 sm:gap-8">
		<div class="flex flex-wrap item justify-center lg:justify-start items-start gap-2">
			<div class="relative w-40 aspect-square mx-auto">
				<div
					class="absolute blur-3xl bg-primary rounded-full w-full h-full animate-pulse opacity-30 -translate-x-2"
				/>
				<div class="absolute blur-lg bg-secondary rounded-full w-full h-full opacity-10" />
				<img
					src="/logo_1_cropped.png"
					width="128"
					height="128"
					alt="The logo of ShipRapid"
					class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
				/>
			</div>
			<h1
				class="font-extrabold text-3xl sm:text-6xl tracking-tight flex flex-col gap-2 items-center lg:items-start mx-auto"
			>
				<span class="text-center lg:text-left">Your startup online,</span>
				<span class="text-center lg:text-left">
					in an <span
						class="bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text"
					>
						instant
					</span>
				</span>
			</h1>
		</div>
		<p
			class="text-lg sm:text-2xl opacity-80 leading-relaxed max-w-lg mx-auto text-center lg:text-left"
		>
			Go <strong class="bg-gradient-to-l from-secondary to-primary text-transparent bg-clip-text">
				online rapidly
			</strong> with our SvelteKit toolbox!. Eveything you need to ship your startup idea very fast,
			in a blink.
		</p>
		<div class="group/cta w-full flex flex-col items-center gap-2">
			<ButtonTextShuffle
				tag="a"
				class="btn btn-primary btn-wide group-hover/cta:btn-accent focus:btn-accent transition-colors duration-500 shadow-2xl"
				from={`get ${title}`}
				to="let's do it!"
				href={ready.redirect}
			/>
			<p>
				or
				<a href="/#pricing" class="link link-secondary link-hover">see Pricing</a>
			</p>
		</div>
	</header>

	<TransitionMorph
		slot="card"
		let:index
		coords={cardsMorph.at(index)}
		class="relative pointer-events-auto"
		gridClass="lg:grid duration-700 ease-in-out"
	>
		<div
			class="lg:mt-0 lg:mockup-window h-full lg:border-2 lg:bg-base-300 transition-colors duration-500 {classes.at(
				index
			)?.external}"
		>
			<div
				class="h-[calc(100%-1.25rem)] max-w-md mx-auto lg:max-w-none flex justify-center items-center p-4 transition-colors duration-500 {classes.at(
					index
				)?.internal}"
			>
				<svelte:component this={cards[index]} class="flex-1 max-w-md mx-auto" />
			</div>
		</div>
	</TransitionMorph>
</HeroCarousel>
