<script lang="ts" context="module">
	const sizes = {
		_: 0,
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
		'2xl': 1536,
		Infinity: Infinity
	} as const;
	type ScreenSize = keyof typeof sizes;
</script>

<script lang="ts">
	import { dev } from '$app/environment';

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	export let from: ScreenSize = '_';
	export let to: ScreenSize = 'Infinity';
	export let cb: () => Promise<any>;
	export let watchResize = dev;
	let show = false;

	let component: any;

	onMount(() => {
		check();
		if (watchResize) {
			window.addEventListener('resize', check);
			return () => window.removeEventListener('resize', check);
		}
	});

	async function check(): Promise<void> {
		const { innerWidth: w } = window;
		if (w > sizes[from] && w <= sizes[to]) {
			try {
				const mod = await cb();
				component = mod.default;
				show = true;
			} catch (err) {
				console.log(err);
			}
		} else {
			show = false;
		}
	}
</script>

{#if show}
	<div in:fade {...$$props}>
		<svelte:component this={component} />
	</div>
{/if}
