<svg class="{$$props.class ?? ''}" width="100%" height="100%" viewBox="0 0 130 25" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M49.5502 15.379V24.3583H54.7126V15.379C56.2236 12.9993 62.3288 2.03524 62.8969 0.89937H56.8937C55.9566 2.56059 49.5502 15.379 49.5502 15.379ZM50.8905 7.71185L48.0507 12.8546C46.6509 10.4125 42.6554 3.41251 41.3745 0.882324H47.3949L50.8905 7.71185Z"
		fill="white"
	/>
	<path
		d="M8.24107 20.2411C8.85196 20.253 9.46209 20.1921 10.0585 20.0592C10.4651 19.9639 10.8503 19.7935 11.1944 19.5566C11.4645 19.3716 11.674 19.1108 11.7964 18.8069C11.9173 18.4946 11.979 18.1623 11.9781 17.8273C11.9739 17.4484 11.8737 17.0768 11.687 16.7471C11.5003 16.4173 11.2331 16.1402 10.9104 15.9416C10.1986 15.4495 8.97466 14.9147 7.23865 14.3372C6.4814 14.0702 5.72412 13.7637 4.96688 13.4173C4.22769 13.085 3.54086 12.6467 2.92797 12.1166C2.32109 11.5831 1.82345 10.9369 1.46266 10.214C1.0622 9.36535 0.86759 8.43431 0.894733 7.49635C0.878676 6.50218 1.08333 5.5168 1.49391 4.61122C1.88995 3.76335 2.47237 3.01589 3.19773 2.42462C3.99006 1.79429 4.89773 1.32456 5.86991 1.04168C7.01828 0.703156 8.21106 0.539463 9.4082 0.556092C10.7711 0.531343 12.1305 0.699615 13.4462 1.05588C14.4161 1.32549 15.3553 1.69472 16.249 2.15769L14.7469 6.26109C14.0547 5.90839 13.3348 5.61301 12.5945 5.37794C11.6732 5.10458 10.7153 4.97527 9.75463 4.99458C8.50706 4.99458 7.6116 5.16686 7.06827 5.51139C6.80173 5.67724 6.58503 5.91206 6.44104 6.19101C6.29707 6.46996 6.2312 6.78264 6.25044 7.09597C6.23206 7.47618 6.33337 7.85257 6.54008 8.17222C6.76772 8.48875 7.0576 8.75547 7.39198 8.95596C7.79309 9.20764 8.21718 9.42052 8.65849 9.59202C9.13747 9.78147 9.66659 9.97535 10.2459 10.1742C11.448 10.6192 12.494 11.0582 13.3838 11.4918C14.1975 11.872 14.9447 12.3813 15.5958 12.9996C16.1819 13.5708 16.6329 14.2654 16.9163 15.0329C17.2265 15.9468 17.3735 16.9079 17.3509 17.8726C17.3509 20.0308 16.5936 21.7044 15.079 22.8932C13.5646 24.0822 11.2852 24.6776 8.24107 24.6795C7.3149 24.6857 6.38951 24.624 5.47234 24.495C4.73466 24.3909 4.00435 24.2399 3.28577 24.0434C2.72815 23.8917 2.18165 23.702 1.65008 23.4753C1.19573 23.2739 0.798184 23.0863 0.5 22.9075L1.96815 18.7699C2.78223 19.198 3.63879 19.5395 4.52387 19.7895C5.7355 20.1143 6.98692 20.2663 8.24107 20.2411Z"
		fill="white"
	/>
	<path
		d="M38.697 0.896973V5.47177H31.6827V24.3786H26.4974V5.47177H19.1794V0.896973H38.697Z"
		fill="white"
	/>
	<path
		d="M85.2314 0.896973V5.47177H78.2314V24.3786H73.0318V5.47177H65.7139V0.896973H85.2314Z"
		fill="white"
	/>
	<path
		d="M98.5526 24.696C94.9121 24.696 92.1395 23.6548 90.2351 21.5723C88.3306 19.4899 87.3774 16.5234 87.3755 12.6726C87.3461 10.9295 87.6418 9.19605 88.2473 7.56122C88.7769 6.14577 89.5907 4.85368 90.6384 3.76452C91.6581 2.72735 92.8932 1.92664 94.256 1.41891C95.709 0.876443 97.2491 0.605013 98.7996 0.618129C99.6659 0.612066 100.531 0.684283 101.384 0.833947C102.072 0.959097 102.752 1.12601 103.42 1.33374C103.922 1.49103 104.411 1.68855 104.882 1.92438C105.272 2.12318 105.55 2.27935 105.734 2.39011L104.28 6.58436C103.515 6.17799 102.709 5.85433 101.875 5.61886C100.858 5.33806 99.8064 5.20326 98.7514 5.21846C97.9767 5.21945 97.2079 5.35493 96.4795 5.61886C95.7332 5.89011 95.0589 6.32927 94.509 6.9024C93.8979 7.55557 93.4308 8.32945 93.1373 9.17417C92.7664 10.2708 92.5915 11.4245 92.6204 12.5818C92.6105 13.5824 92.7201 14.5804 92.947 15.555C93.1458 16.4154 93.5201 17.2255 94.046 17.9347C94.5701 18.6157 95.2525 19.1586 96.0337 19.5164C96.9683 19.9292 97.9829 20.128 99.0041 20.0986C99.6636 20.1033 100.322 20.0585 100.975 19.9651C101.497 19.8899 102.015 19.7837 102.525 19.6471C102.934 19.544 103.333 19.41 103.721 19.2465C104.066 19.0896 104.378 18.9447 104.658 18.8122L106.061 22.9638C105.121 23.502 104.109 23.9054 103.056 24.1622C101.586 24.5404 100.071 24.7199 98.5526 24.696Z"
		fill="white"
	/>
	<path
		d="M124.011 0.930664H129.5V24.4209H124.011V14.354H115.165V24.4209H109.977V0.930664H115.165V9.77633H124.011V0.930664Z"
		fill="white"
	/>
</svg>
