<script lang="ts">
	import Lazy from '$internal/components/lazy/Lazy.svelte';
</script>

<section
	class="w-screen relative isolate bg-gradient-to-b from-base-300 to-transparent px-8 {$$props.class ??
		''}"
>
	<div class="sm:pt-40 2xl:pt-60 z-0 flex flex-col gap-10 items-center">
		<h2 class="mt-20 text-center">
			<span class="text-5xl bg-gradient-to-l from-accent to-primary text-transparent bg-clip-text font-bold">
				+25 hours
			</span>
			<span class="mt-4 block text-3xl">of time saved!</span>
		</h2>
	</div>
	<Lazy to="lg" cb={() => import('./TimeSaverList.svelte')} watchResize class="mt-10" />
	<Lazy from="lg" cb={() => import('./TimeSaverVisualizer.svelte')} watchResize class="mt-10" />
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		version="1.1"
		viewBox="0 0 900 120.85"
		class="hidden sm:block absolute top-0 left-0 -z-10"
	>
		<path
			d="M0 81L15 80.3C30 79.7 60 78.3 90 77C120 75.7 150 74.3 180 83.8C210 93.3 240 113.7 270 119.2C300 124.7 330 115.3 360 107C390 98.7 420 91.3 450 84.2C480 77 510 70 540 73.3C570 76.7 600 90.3 630 91.8C660 93.3 690 82.7 720 75.5C750 68.3 780 64.7 810 68.5C840 72.3 870 83.7 885 89.3L900 95L900 0L885 0C870 0 840 0 810 0C780 0 750 0 720 0C690 0 660 0 630 0C600 0 570 0 540 0C510 0 480 0 450 0C420 0 390 0 360 0C330 0 300 0 270 0C240 0 210 0 180 0C150 0 120 0 90 0C60 0 30 0 15 0L0 0Z"
			fill="#828df8"
		/>
		<path
			d="M0 43L15 51.5C30 60 60 77 90 76.5C120 76 150 58 180 56.5C210 55 240 70 270 76.7C300 83.3 330 81.7 360 85.2C390 88.7 420 97.3 450 100.2C480 103 510 100 540 96.5C570 93 600 89 630 80.3C660 71.7 690 58.3 720 50.8C750 43.3 780 41.7 810 43.3C840 45 870 50 885 52.5L900 55L900 0L885 0C870 0 840 0 810 0C780 0 750 0 720 0C690 0 660 0 630 0C600 0 570 0 540 0C510 0 480 0 450 0C420 0 390 0 360 0C330 0 300 0 270 0C240 0 210 0 180 0C150 0 120 0 90 0C60 0 30 0 15 0L0 0Z"
			fill="#5da1ff"
		/>
		<path
			d="M0 85L15 80.2C30 75.3 60 65.7 90 57.8C120 50 150 44 180 40.2C210 36.3 240 34.7 270 39C300 43.3 330 53.7 360 59.5C390 65.3 420 66.7 450 65.8C480 65 510 62 540 63.2C570 64.3 600 69.7 630 73.3C660 77 690 79 720 73.3C750 67.7 780 54.3 810 49.5C840 44.7 870 48.3 885 50.2L900 52L900 0L885 0C870 0 840 0 810 0C780 0 750 0 720 0C690 0 660 0 630 0C600 0 570 0 540 0C510 0 480 0 450 0C420 0 390 0 360 0C330 0 300 0 270 0C240 0 210 0 180 0C150 0 120 0 90 0C60 0 30 0 15 0L0 0Z"
			fill="#3fb1fe"
		/>
		<path
			d="M0 22L15 21.3C30 20.7 60 19.3 90 18.8C120 18.3 150 18.7 180 20C210 21.3 240 23.7 270 24.5C300 25.3 330 24.7 360 25.7C390 26.7 420 29.3 450 34.2C480 39 510 46 540 49.7C570 53.3 600 53.7 630 48.7C660 43.7 690 33.3 720 29.7C750 26 780 29 810 34.3C840 39.7 870 47.3 885 51.2L900 55L900 0L885 0C870 0 840 0 810 0C780 0 750 0 720 0C690 0 660 0 630 0C600 0 570 0 540 0C510 0 480 0 450 0C420 0 390 0 360 0C330 0 300 0 270 0C240 0 210 0 180 0C150 0 120 0 90 0C60 0 30 0 15 0L0 0Z"
			fill="#3abff8"
		/>
	</svg>
</section>
