<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="stytch"
	code={`flowchart TB
	User([🧑 User]) --> Auth[[/auth]]
	Auth --> Google{{🔓 Sign in with Google}}
	Auth --> MagicLink{{✉️ Magic Link}}
	Google --o DB[(MongoDB)]
	MagicLink --o DB[(MongoDB)]
	Google --> Dashboard[[⚙️ /dashboard]]`}
/>
