<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="mongodb"
	code={`flowchart TB
	Stripe([💳 Stripe]) -. setup .-> Mongo
	Auth([🔓 Auth]) -. setup .-> Mongo
	Mongoose{{Mongoose}} -- enhance --> Mongo
	Mongo[(MongoDB)]
	Mongo --> Hosted{{☁️ Hosted}}
	Mongo -- docker --> Local{{💻 Local}}
	Mongo -- dev --> Admin[[Admin panel]]`}
/>
