<script lang="ts">
	export let tag: keyof HTMLElementTagNameMap = 'article';
	export let tabindex = 0;
	export let arrow = false;
	export let plus = false;
	$: icon = arrow ? ' collapse-arrow' : plus ? 'collapse-plus' : '';

	export let open = false;
	let _open = open;
</script>

<svelte:element
	this={tag}
	{tabindex}
	class="collapse {icon} {$$props.class ?? ''}"
	on:focus={() => (_open = true)}
	on:blur={() => (_open = false)}
>
	<header class="collapse-title">
		<slot name="header" open={_open}>header</slot>
	</header>
	<div class="collapse-content"><slot open={_open}>default</slot></div>
</svelte:element>
