import HeroCardMongoDB from './card/HeroCardMongoDB.svelte';
import HeroCardSEO from './card/HeroCardSEO.svelte';
import HeroCardStripe from './card/HeroCardStripe.svelte';
import HeroCardStytch from './card/HeroCardStytch.svelte';
import HeroCardSvelteKit from './card/HeroCardSvelteKit.svelte';
import HeroCardTailwindCSS from './card/HeroCardTailwindCSS.svelte';

import HeroIconMongoDB from './icon/HeroIconMongoDB.svelte';
import HeroIconSEO from './icon/HeroIconSEO.svelte';
import HeroIconStripe from './icon/HeroIconStripe.svelte';
import HeroIconStytch from './icon/HeroIconStytch.svelte';
import HeroIconSvelteKit from './icon/HeroIconSvelteKit.svelte';
import HeroIconTailwindCSS from './icon/HeroIconTailwindCSS.svelte';

export const cards = {
	HeroCardMongoDB,
	HeroCardSEO,
	HeroCardStripe,
	HeroCardStytch,
	HeroCardSvelteKit,
	HeroCardTailwindCSS
};

export const icons = {
	HeroIconMongoDB,
	HeroIconSEO,
	HeroIconStripe,
	HeroIconStytch,
	HeroIconSvelteKit,
	HeroIconTailwindCSS
};
