<script lang="ts">
	export let flipped: boolean;
	export let width: number;
	export let height: number;
	export let durationMs = 300;
</script>

<div
	class="flip-card {$$props.class ?? ''}"
	style="width: {width}px; height: {height}px; perspective: 1000px;"
>
	<div
		class="flip-card-inner"
		style="{flipped ? 'transform: rotateY(180deg)' : 'transform: rotateY(0)'}; --durationMs: {durationMs}ms;"
	>
		<div class="flip-card-front" style="opacity: {flipped ? '0' : "100"}; --durationMs: {durationMs}ms;">
			<slot name="front" {flipped} />
		</div>
		<div class="flip-card-back" style="opacity: {!flipped ? '0' : "100"}; --durationMs: {durationMs}ms;">
			<slot name="back" {flipped} />
		</div>
	</div>
</div>

<style>
	.flip-card {
		width: min-content;
	}

	.flip-card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		transition: transform var(--durationMs) ease-in-out;
		transform-style: preserve-3d;
	}

	.flip-card-front,
	.flip-card-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transition: opacity var(--durationMs) ease-in-out;
	}

	.flip-card-back {
		transform: rotateY(180deg);
	}
</style>
