<script lang="ts">
	import { sections } from '$const/ids';
	import CollapseFocus from '$internal/components/collapse/CollapseFocus.svelte';
	import Link from '$internal/components/link/Link.svelte';
	import SectionFixedScrollable from '$internal/components/section/SectionFixedScrollable.svelte';
	import { timeAgo } from '$internal/utils/date';

	const lastUpdate = new Date('2023-10-19T09:45:30');
</script>

<SectionFixedScrollable class="px-4 {$$props.class ?? ''}">
	<div slot="fixed" class="mx-auto prose lg:prose-lg">
		<h2 id={sections.landing.faq}>Frequently Asked Questions</h2>
		<p>
			Can't find the answer you're looking for? <br /> Contact us on or by <Link
				href="mailto:hello@shiprap.id"
				class="link link-secondary link-hover"
			>
				hello@shiprap.id
			</Link>
		</p>
	</div>

	<ul slot="scrollable" class="w-fit flex flex-col divide-y-2 divide-secondary/10">
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<h3 slot="header" class="!m-0 !text-lg font-semibold">What's included?</h3>
				<p>
					It's your canvas for creation, designed to evolve with your journey and ours, while also
					saving us oodles of time. For an extensive list of features, dive into the <Link
						href="/details"
						class="link link-secondary link-hover"
					>
						details page.
					</Link>
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<h3 slot="header" class="!m-0 !text-lg font-semibold">Can I get a refund?</h3>
				<p>
					Yes! You can request a refund within <b>14 days</b> of your purchase. You can read more on
					the <Link href="/refunds" class="link link-hover link-secondary">refunds page.</Link>
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<span slot="header" class="text-lg font-semibold">
					Can I use it with a different tech stack?
				</span>
				<p>
					Absolutely, you're good to go as long as you're cozy with Svelte and SvelteKit. The beauty
					of Svelte is its knack for seamlessly integrating with any library you fancy.
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<span slot="header" class="text-lg font-semibold">Is this merely a template?</span>
				<p>
					Nope, it's <u> more than just a template</u>. It's a complete project rooted in <b>best
					practices</b>. Among various things, it saves you from the ground-up grind and serves you with authentication and
					payment functionality right off the bat - crucial for any startup. Plus, it's designed
					with clarity and the ability to expand in mind.
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<span slot="header" class="text-lg font-semibold">How frequently do you update it?</span>
				<p class="text-primary">Last update {timeAgo(lastUpdate)}.</p>
				<p>
					<b>Regularly</b> and always accompanied by <b>meticulous documentation</b>.
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<span slot="header" class="text-lg font-semibold">JavaScript or TypeScript?</span>
				<p>
					<b>TypeScript</b> all the way. While we might ponder replicating components in JavaScript and
					documenting them with JSDocs in the future, for now, let's stick to the TypeScript track.
				</p>
			</CollapseFocus>
		</li>
		<li>
			<CollapseFocus arrow class="prose lg:prose-lg">
				<span slot="header" class="text-lg font-semibold">Can I use PayPal?</span>
				<p>Yep!</p>
			</CollapseFocus>
		</li>
	</ul>
</SectionFixedScrollable>
