<script context="module" lang="ts">
	export type CarouselFullScreenSlideOptions = {
		backgroundColor?: {
			start?: string;
			end?: string;
		};
		opacity?: {
			start?: number;
			end?: number;
		};
	};
</script>

<script lang="ts">
	import { browser } from '$app/environment';

	export let reverse = false;
	export let options: CarouselFullScreenSlideOptions = {
		backgroundColor: {},
		opacity: { start: 1, end: 1 }
	};

	let section: HTMLElement;
	let fixed: HTMLElement;
	let normalized = 0;
	let Y = browser ? window.scrollY : 0;

	$: {
		if (browser && section) {
			const y = Y + window.screen.availHeight / 2;

			const rect = (Y < 640 ? fixed : section).getBoundingClientRect();
			const start = rect.top + window.scrollY;
			const end = rect.height + start;

			if (y < start) {
				normalized = 0;
			} else if (y > end) {
				normalized = 1;
			} else {
				normalized = (y - start) / (end - start);
			}
		}
	}
</script>

<svelte:body
	on:wheel|passive={() => (Y = window.scrollY)}
	on:scroll|passive={() => (Y = window.scrollY)}
	on:touchmove={() => (Y = window.scrollY)}
	on:touchend={() => (Y = window.scrollY)}
/>

<section
	bind:this={section}
	class="css-controlled w-screen flex flex-col {reverse
		? 'md:flex-row-reverse'
		: 'md:flex-row'} md:px-20 gap-10 {$$props.class ?? ''}"
	style="--normalized: {normalized}; --bg-start: {options.backgroundColor
		?.start}; --bg-end: {options.backgroundColor?.end}; --opacity-start: {options.opacity
		?.start}; --opacity-end: {options.opacity?.end};"
>
	<span
		bind:this={fixed}
		class="flex-1 md:sticky md:top-[20%] md:bottom-[20%] md:my-[10%] md:h-max"
	>
		<slot name="fixed" {normalized}>
			<div class="flex flex-col gap-2 items-center">
				<code>normalized</code>
				<code class="text-7xl">
					{normalized.toFixed(3)}
				</code>
				<p class="text-xs">*the actual value is not rounded.</p>
			</div>
		</slot>
	</span>
	<div class="flex-1 h-full mx-auto">
		<slot name="scrollable" {normalized} />
	</div>
</section>

<style>
	/** Reference <https://css-tricks.com/books/greatest-css-tricks/scroll-animation/> */
	.css-controlled {
		animation: update-properties 1s linear;
		/* Pause the animation */
		animation-play-state: paused;
		/* Bind the animation to normalized */
		animation-delay: calc(var(--normalized) * -1s);
		/* These last 2 properites clean up overshoot weirdness */
		animation-iteration-count: 1;
		animation-fill-mode: both;

		/* css controller properties */
		background-color: var(--bg-start);
		opacity: var(--opacity-start, 1);
	}

	@keyframes update-properties {
		to {
			background-color: var(--bg-end);
			opacity: var(--opacity-end, 1);
		}
	}
</style>
