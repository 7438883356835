<script lang="ts">
	export let faded = true;
	export let gapRem = 1;
	export let durationMs = 20000;
	export let rightToLeft = true;
	export let items: any[];
	export let normalized: number | undefined = undefined;

	$: duration = normalized ? 1000 : durationMs;
	$: direction = rightToLeft ? 'normal' : 'reverse';
	$: halfGap = gapRem / 2;
	$: _normalized = normalized ? `--normalized: ${normalized}` : '';
</script>

<div class="h-full motion-safe:overflow-hidden {$$props.class ?? 'max-w-full'}" class:faded>
	<ul
		class="scroll h-full w-max flex flex-wrap items-center py-4 motion-safe:flex-nowrap"
		data-normalized={typeof normalized === 'number'}
		style="column-gap: {gapRem}rem; --half-gap: {halfGap}rem; --duration: {duration}ms; --direction: {direction}; {_normalized}"
	>
		{#each items as item, index}
			<li>
				<slot {item} {index}>
					<div class="aspect-square w-fit flex flex-col items-center gap-4">
						<strong>index: {index}</strong>
						<pre>{JSON.stringify(item, null, 2)}</pre>
					</div>
				</slot>
			</li>
		{/each}
		{#each items as item, index}
			<li aria-label="hidden">
				<slot {item} {index}>
					<div class="aspect-square w-fit flex flex-col items-center gap-4">
						<strong>index: {index}</strong>
						<pre>{JSON.stringify(item, null, 2)}</pre>
					</div>
				</slot>
			</li>
		{/each}
	</ul>
</div>

<style>
	@media (prefers-reduced-motion: no-preference) {
		.scroll {
			animation: scroll var(--duration) linear infinite var(--direction);
		}

		.scroll[data-normalized='true'] {
			/* Pause the animation */
			animation-play-state: paused;
			/* Bind the animation to scroll */
			animation-delay: calc(var(--normalized, 0) * -1s);
			/* These last 2 properites clean up overshoot weirdness */
			animation-iteration-count: 1;
			animation-fill-mode: both;
		}

		.faded {
			-webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
			mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
		}
	}

	@keyframes scroll {
		to {
			transform: translate(calc(-50% - var(--half-gap)));
		}
	}
</style>
