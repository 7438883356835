<script lang="ts">
	import Faq from '$lib/components/landing/faq/Faq.svelte';
	import GradientGrid from '$internal/components/gradient/GradientGrid.svelte';
	import Hero from '$lib/components/landing/hero/Hero.svelte';
	import PrincingCards from '$lib/components/landing/pricing/PrincingCards.svelte';
	import SectionAmazingComponents from '$lib/components/landing/sections/amazingComponents/SectionAmazingComponents.svelte';
	import SectionNoCardRequired from '$lib/components/landing/sections/noCardRequired/SectionNoCardRequired.svelte';
	import SectionTimeSaved from '$lib/components/landing/sections/timeSaved/SectionTimeSaved.svelte';
</script>

<GradientGrid px={30} />
<Hero />
<PrincingCards />
<SectionTimeSaved class="mb-20"/>
<SectionAmazingComponents />
<SectionNoCardRequired class="mt-10"/>
<Faq class="my-10"/>
