<svg
	class={$$props.class ?? ''}
	width="100%"
	height="100%"
	viewBox="0 0 120 250"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M82.3229 28.6444C71.5367 15.8469 62.2485 2.84945 60.351 0.149971C60.1512 -0.0499903 59.8515 -0.0499903 59.6518 0.149971C57.7542 2.84945 48.4661 15.8469 37.6798 28.6444C-54.9019 146.721 52.2613 226.406 52.2613 226.406L53.1601 227.006C53.959 239.303 55.9565 257 55.9565 257H59.9514H63.9463C63.9463 257 65.9438 239.403 66.7428 227.006L67.6416 226.306C67.7414 226.306 174.905 146.721 82.3229 28.6444ZM59.9514 224.606C59.9514 224.606 55.1576 220.507 53.8592 218.407V218.208L59.6518 89.6325C59.6518 89.2326 60.2511 89.2326 60.2511 89.6325L66.0436 218.208V218.407C64.7453 220.507 59.9514 224.606 59.9514 224.606Z"
		fill="white"
	/>
</svg>
