<script lang="ts">
	import Dropdown from '../dropdown/Dropdown.svelte';
	import { themes } from '$const/theme';
	import ThemePreviewLarge from '$internal/components/theme/ThemePreviewLarge.svelte';

	export let top = false;
</script>

<Dropdown {top} class="z-50 not-prose {$$props.class}">
	<button slot="button" class="btn btn-neutral normal-case">Themes dropdown</button>

	{#each themes as theme}
		<li class="mr-1">
			<ThemePreviewLarge {theme} />
		</li>
	{/each}
</Dropdown>
