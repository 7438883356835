<script lang="ts">
	export let px = 30;
</script>

<svg
	class="absolute inset-0 -z-50 h-full w-full stroke-secondary/20 stroke-2 [mask-image:radial-gradient(closest-side_at_50%_50%,white,transparent)] {$$props.class ?? ''}"
	aria-hidden="true"
	style={$$props.style}
>
	<defs>
		<pattern
			id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
			width={px}
			height={px}
			x="50%"
			y="-1"
			patternUnits="userSpaceOnUse"
		>
			<path d="M.5 200V.5H200" fill="none" />
		</pattern>
	</defs>
	<rect
		width="100%"
		height="100%"
		stroke-width="0"
		fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
	/>
</svg>