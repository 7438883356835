<script context="module" lang="ts">
	export type Coordinates = [x1: number, x2: number, y1: number, y2: number];
</script>

<script lang="ts">
	export let coords: Coordinates = [0, 100, 0, 100];
	export let gridClass = 'grid duration-700 ease-in-out'

	$: c = `${coords[0] || 0}% auto ${100 - coords[1] || 0}%`;
	$: r = `${coords[2] || 0}% auto ${100 - coords[3] || 0}%`;
</script>

<div
	class="w-full h-full transform-gpu {gridClass}"
	style="grid-template-columns: {c}; grid-template-rows: {r};"
>
	<span />
	<span />
	<span />
	<span />
	<div class="transition-all transform-gpu duration-700" {...$$props}>
		<slot />
	</div>
	<span />
	<span />
	<span />
	<span />
</div>