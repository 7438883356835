<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="seo"
	code={`flowchart TB
	BaseMeta[base &ltmeta> tag] --> Page
	OG[OG tags] --> Page
	Twitter[Twitter tags] --> Page
	Robots[[robots.txt]] --> SEO
	Page[[all pages]] --> SEO
	Sitemap[[sitemap.xml]] -- dynamic --> SEO
	SEO{{SEO}}`}
/>
