<script lang="ts">
	export let end = false;
	export let top = false;
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
	class="dropdown {end ? 'dropdown-end' : ''} {top
		? 'dropdown-top'
		: 'dropdown-bottom'} {$$props.class}"
>
	<label tabindex="0"><slot name="button" /></label>
	<ul
		tabindex="0"
		class="{top
			? 'mb-2'
			: 'mt-2'} dropdown-content z-[1] p-2 shadow bg-base-200 rounded-box w-52 max-h-96 overflow-y-scroll flex flex-col gap-2"
	>
		<slot />
	</ul>
</div>
