<script lant="ts">
	import Carousel from '$internal/components/carousel/Carousel.svelte';
	import SectionFixedScrollable from '$internal/components/section/SectionFixedScrollable.svelte';
	import LightDarkModeToggle from '$internal/components/theme/LightDarkModeToggle.svelte';
	import ThemesDropdown from '$internal/components/theme/ThemesDropdown.svelte';
</script>

<SectionFixedScrollable class="px-4 {$$props.class ?? ''}">
	<div
		slot="fixed"
		let:normalized
		class="flex flex-col gap-10 mockup-code shadow-2xl max-w-md mx-auto overflow-hidden"
	>
		<progress class="progress progress-primary w-full" value={normalized * 100} max="100" />
		<div class="flex flex-col gap-2 items-center card card-compact w-fit mx-auto">
			<div class="card-body">
				<code class="card-title text-center block">normalized</code>
				<span class="countdown font-mono text-6xl">
					{#each normalized.toFixed(3).split('') as char}
						{#if char == '.'}
							.
						{:else}
							<span style="--value:{char};" />
						{/if}
					{/each}
				</span>
			</div>
		</div>
		<Carousel
			{normalized}
			items={Array.from({ length: 9 }).map(
				(_, index) =>
					[
						'mask-squircle bg-primary',
						'mask-heart bg-secondary',
						'mask-hexagon bg-accent',
						'mask-pentagon bg-primary',
						'mask-diamond bg-secondary',
						'mask-circle bg-accent',
						'mask-parallelogram bg-primary',
						'mask-star bg-secondary',
						'mask-triangle bg-accent'
					][index]
			)}
			class="max-w-sm mx-auto"
			let:item
		>
			<div class="h-20 aspect-square shadow-2xl mask {item}" />
		</Carousel>
	</div>

	<div slot="scrollable" class="prose-lg lg:prose-xl mx-auto">
		<h3 class="bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text">
			Amazing components!
		</h3>
		<p>Take this section with a <u>fixed element</u> and <u>scrollable text</u>.</p>
		<ul class="list-disc">
			<li>Receive the <code>normalized</code> scrolling position</li>
			<li>plug it in the DaisyUI <code>{`<progress />`}</code></li>
			<li>pass it to <code>{`<Carousel />`}</code></li>
		</ul>
		<p>
			The components always relay on <span class="text-primary">CSS-only</span> solution whenever possible.
		</p>
		<p>
			They are always consistent with the theme and fit immediately without adding additional
			classes.
		</p>
		<div class="flex justify-center items-end gap-4">
			<LightDarkModeToggle />
			<ThemesDropdown top />
		</div>
	</div>
</SectionFixedScrollable>

<style>
	.countdown > *:before {
		content: '0\A 1\A 2\A 3\A 4\A 5\A 6\A 7\A 8\A 9\A';
	}
</style>
