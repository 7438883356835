<script lang="ts">
	import type { RegisteredTheme } from '$const/theme';
	import { theme as store } from '$internal/stores/theme';

	export let theme: RegisteredTheme;
</script>

<button
	type="button"
	class="w-fullhover:border-base-content/40 overflow-hidden rounded-lg border w-full"
	on:click={() => store.set(theme)}
	on:keydown={() => store.set(theme)}
>
	<div data-theme={theme} class="bg-base-100 text-base-content w-full">
		<div class="grid grid-cols-5 grid-rows-3">
			<div class="bg-base-200 col-start-1 row-span-2 row-start-1" />
			<div class="bg-base-300 col-start-1 row-start-3" />
			<div
				class="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2"
			>
				<div class="font-bold text-left">{theme}</div>
				<div class="flex flex-wrap gap-1" data-svelte-h="svelte-1kw79c2">
					<div class="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
						<div class="text-primary-content text-sm font-bold">A</div>
					</div>
					<div
						class="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6"
					>
						<div class="text-secondary-content text-sm font-bold">A</div>
					</div>
					<div class="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
						<div class="text-accent-content text-sm font-bold">A</div>
					</div>
					<div class="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
						<div class="text-neutral-content text-sm font-bold">A</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</button>
