<script lang="ts">
	import { browser } from '$app/environment';
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	import TransitionFlip from '../transition/TransitionFlip.svelte';
	import TransitionMorph, { type Coordinates } from '../transition/TransitionMorph.svelte';
	import Carousel from '../carousel/Carousel.svelte';
	import { headerHeightPx } from '../layout/Layout.svelte';

	export let icons: any[];
	export let durationMs = 20000;
	export let iconsCoordinates: Coordinates[] = [];

	let right: HTMLDivElement;
	let carousel: HTMLDivElement;
	let index = 0;
	let previousIndex = 0;
	let flipped = false;

	$: iconCoordinates = iconsCoordinates.at(index) || ([0, 10, 0, 10] as Coordinates);

	let iconWidth: number;
	let iconHeight: number;
	let width: number;
	let height: number;
	onMount(() => {
		const rect = right.getBoundingClientRect();
		width = rect.width;
		height = rect.height;
	});

	$: {
		if (browser) {
			iconWidth = (width / 100) * (iconCoordinates[1] - iconCoordinates[0]);
			iconHeight = (height / 100) * (iconCoordinates[3] - iconCoordinates[2]);
		}
	}

	async function update(newIndex: number): Promise<void> {
		if (newIndex === index) return;
		if (window.innerWidth < 1024) {
			window.scrollTo({
				top: carousel.getBoundingClientRect().top + window.scrollY - headerHeightPx,
				behavior: 'smooth'
			});
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		previousIndex = index;
		index = newIndex;
		flipped = !flipped;
	}

	/**
	 * Note the pointer-events-node and pointer-events-auto on the wrapping of the card slot.
	 * That is necessary in order to make thinks like links and buttons clickable. Otherwise the
	 * icon's TransitionMorph would prevent it (since it covers up it with the same width and height).
	 */
</script>

<div class="layout {$$props.class ?? ''}">
	<div class="l px-10">
		<slot name="welcome">Fill with your catchphrase</slot>
	</div>
	<div bind:this={right} class="r relative h-full w-full">
		{#if width}
			<span in:fade class="pointer-events-none lg:max-w-none">
				<slot name="card" {index} />
				<TransitionMorph
					coords={iconCoordinates}
					gridClass="hidden lg:grid duration-700 ease-in-out absolute inset-0"
				>
					<TransitionFlip width={iconWidth} height={iconHeight} {flipped}>
						<svelte:component this={icons[flipped ? previousIndex : index]} slot="front" />
						<svelte:component this={icons[flipped ? index : previousIndex]} slot="back" />
					</TransitionFlip>
				</TransitionMorph>
			</span>
		{/if}
	</div>
	<div class="c overflow-hidden" bind:this={carousel}>
		<slot name="below" {update}>
			<Carousel items={icons} {durationMs} gapRem={6} let:item let:index>
				<button
					type="button"
					on:click={() => update(index)}
					on:keydown={() => update(index)}
					class="h-[10vh] aspect-square bg-base-300 shadow-2xl hover:scale-105 transition-transform p-4 mask mask-hexagon grid place-content-center"
				>
					<svelte:component this={item} />
				</button>
			</Carousel>
		</slot>
	</div>
</div>

<style>
	.layout {
		width: 100vw;
		min-height: calc(100vh - var(--header-height, 64px));
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: calc(100vh - var(--header-height) - 20vh) 20vh auto;
		grid-template-areas: 'l' 'c' 'r';
	}

	/* lg */
	@media screen and (min-width: 1024px) {
		.layout {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: calc(100vh - var(--header-height) - 20vh) 20vh;
			grid-template-areas: 'l r' 'c c';
		}
	}

	.l {
		grid-area: l;
	}

	.r {
		grid-area: r;
	}

	.c {
		grid-area: c;
	}
</style>
