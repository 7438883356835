<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="sveltekit"
	code={`graph TD
	SK --> SPA
	SK --> SSR
	SK([SvelteKit]) --> SSG
	SPA(🚀 SPA)
	SSR(🏰 SSR)
	SSG(⚡ SSG)
	SSG --> Rest[[landing\ncompany\nservices\nprivacy]]
	SSR --> Dashboard[[️⚙️ Dashboard]]
	SPA -. adaptable .-> Dashboard`}
/>
