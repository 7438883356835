<script lang="ts">
	import SectionFixedScrollable from '$internal/components/section/SectionFixedScrollable.svelte';
	import CreditCard from '../CreditCard.svelte';
</script>

<SectionFixedScrollable reverse class="px-4 {$$props.class ?? ''}">
	<CreditCard
		slot="fixed"
		let:normalized
		frame={normalized}
		holder="Not your card"
		class="w-[80vw] md:w-full max-w-md mx-auto"
	/>
	<div slot="scrollable" class="prose-lg lg:prose-xl mx-auto">
		<h3 class="bg-gradient-to-l from-secondary to-primary text-transparent bg-clip-text">
			Get Started Credit Card-Free
		</h3>
		<p>
			With our integrated third-party services, there's no need to pull out your credit card to
			begin. We've deliberately made it <b>hassle-free</b>, so you can dive into your online venture
			smoothly.
		</p>

		<h4 class="text-secondary text-2xl">Test the Waters</h4>
		<p>
			We firmly believe in <u>letting the market decide</u> whether paid plans or other preferred
			providers are worth it. You can assess, experiment, and experience the value firsthand before
			committing financially.
		</p>
	</div>
</SectionFixedScrollable>
