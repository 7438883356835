<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="tailwindcss"
	code={`flowchart TB
	Components([components]) --> Daisy
	Themes([20+ themes]) --> Daisy
	Daisy{{DaisyUI}} --> Theme
	Tailwind{{TailwindCSS}} --> Theme
	Theme[🖌️ Theme] -- reactive styling --> UI[[UI]]`}
/>
