<svg
	class='h-full'
	xmlns="http://www.w3.org/2000/svg"
	version="1.1"
	width="100%"
	height="100%"
	viewBox="0 0 512 616"
	style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
	xmlns:xlink="http://www.w3.org/1999/xlink"
>
	<g
		><path
			style="opacity:0.996"
			fill="#ff3e00"
			d="M 305.5,-0.5 C 316.5,-0.5 327.5,-0.5 338.5,-0.5C 414.552,9.05717 468.386,48.7238 500,118.5C 505.436,133.576 509.269,148.909 511.5,164.5C 511.5,176.167 511.5,187.833 511.5,199.5C 508.632,227.089 499.632,252.589 484.5,276C 508.958,324.954 510.791,374.788 490,425.5C 477.702,452.465 459.536,474.632 435.5,492C 384.833,524.333 334.167,556.667 283.5,589C 259.037,603.765 232.703,612.599 204.5,615.5C 193.5,615.5 182.5,615.5 171.5,615.5C 103.742,607.216 52.9085,573.549 19,514.5C 8.91889,494.482 2.41889,473.482 -0.5,451.5C -0.5,438.5 -0.5,425.5 -0.5,412.5C 2.7637,386.293 11.4304,361.96 25.5,339.5C 7.58007,304.079 2.08007,266.746 9,227.5C 17.4605,183.752 39.6272,148.919 75.5,123C 128.833,89 182.167,55 235.5,21C 257.611,9.46005 280.945,2.29339 305.5,-0.5 Z"
		/></g
	>
	<g
		><path
			style="opacity:1"
			fill="#fffefe"
			d="M 314.5,69.5 C 369.097,68.0329 408.263,91.6995 432,140.5C 441.905,166.496 441.905,192.496 432,218.5C 431.282,219.451 430.449,219.617 429.5,219C 409.62,203.893 387.62,192.726 363.5,185.5C 364.276,157.443 350.61,143.943 322.5,145C 317.864,145.879 313.531,147.545 309.5,150C 261.167,181 212.833,212 164.5,243C 152.513,256.195 151.346,270.362 161,285.5C 173.149,299.053 187.649,302.22 204.5,295C 224.833,282 245.167,269 265.5,256C 303.61,237.298 341.276,238.298 378.5,259C 427.849,293.207 442.682,338.707 423,395.5C 415.566,410.188 405.4,422.688 392.5,433C 341.833,465.333 291.167,497.667 240.5,530C 191.21,555.1 145.877,549.433 104.5,513C 72.2623,479.331 63.2623,440.164 77.5,395.5C 78.552,395.351 79.552,395.517 80.5,396C 100.057,410.946 121.724,421.946 145.5,429C 144.621,450.43 154.288,464.43 174.5,471C 186.782,471.906 197.782,468.573 207.5,461C 249.664,433.75 291.997,406.75 334.5,380C 354.952,368.647 360.118,352.48 350,331.5C 337.384,316.848 322.217,313.348 304.5,321C 284.167,334 263.833,347 243.5,360C 190.488,383.397 143.988,374.231 104,332.5C 78.7283,301.138 72.0616,266.138 84,227.5C 93.0969,205.075 107.93,187.575 128.5,175C 177.002,144.583 225.336,113.916 273.5,83C 286.596,76.3564 300.263,71.8564 314.5,69.5 Z"
		/></g
	>
</svg>
