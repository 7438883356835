<script lang="ts">
	import Mermaid from '$internal/components/mermaid/Mermaid.svelte';
</script>

<Mermaid
	{...$$props}
	id="stripe"
	code={`flowchart LR
		User([🧑 User]) --> Stripe{{💳 Stripe}}
		Stripe == webhook ==> Server
		Server --o DB[(MongoDB)]`}
/>
